<template>
  <div class="monitorVehicle">
    <div class="searchWrapper" @keydown.enter.prevent="searchData">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form
        :inline="true"
        label-position="right"
        class="demo-form-inline bgFFF paddingLR20 paddingT20 marginB20"
      >
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
              <el-autocomplete
                class="inline-input"
                v-model="formInline.plateNumber"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                value-key="plateNumber"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                pageNum = 1;
                searchData();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper paddingB10 bgFFF">
      <el-table
        v-loading="loading"
        :data="tableData"
        @cell-click="detailShow"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" :selectable="checkboxSelect" width="55">
        </el-table-column>
        <el-table-column align="center" label="标题内容" width="400">
          <!-- <template slot-scope="scope">
            <div>
              <span class="circle" v-if="scope.row.unread == 0"></span>
              {{ scope.row.msgTitle }}
            </div>
          </template> -->
          监控车辆入场通知
        </el-table-column>
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
      <el-button
        type="primary"
        @click="
          pageNum = 1;
          tagRead();
        "
        :disabled="subscriptionFlag"
        >标记已读</el-button
      >
      <el-button
        type="primary"
        @click="
          pageNum = 1;
          allRead();
        "
        :disabled="disabled"
        >全部已读</el-button
      >
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <picDetail
      :infoSize="4"
      :infoDetail="parkDetail"
      :rowData="rowData"
      :detailFlag="dialogVisible"
      :tableOrder="tableOrder"
      :parkRecordIdTable="parkRecordIdTable"
      @close="dialogVisible = false"
      :total="total1"
      :tableOrderdata="tableOrderdata"
      :parkRecordIdData="parkRecordIdData"
      @getOrder="clildrenHandleCurrentChange"
      :entryPic="entryPic"
      :exitPic="exitPic"
    ></picDetail>
  </div>
</template>

<script>
import picDetail from "@/components/picDetail/picDetail";
import {
  getDt,
  // dateFormat,
  // setIndex,
  // exportExcelNew,
} from "../../../common/js/public.js";
import Bus from "@/common/js/hub.js";
export default {
  data() {
    return {
      formInline: {
        plateNumber: "",
        parkId: "",
      },
      loading: false,
      pageNum: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
        },
        {
          prop: "exitTime",
          label: this.$t("list.leaving_time"),
        },
        {
          prop: "msgType",
          label: "类型",
          formatter: (row) => {
            console.log(row);
            // let obj = {
            //   "0": "管理员通知",
            //   "3": "设备异常报警",
            //   "2": "监控车辆入场报警",
            // };
            // return obj[row.msgType];
            return "监控车辆";
          },
        },
        // {
        //   prop: "type",
        //   label: "报警类型",
        //   formatter: (cellValue) => {
        //     if (cellValue.type == 0) {
        //       return "超长停车";
        //     } else {
        //       return "套牌车";
        //     }
        //   },
        // },
      ],
      dialogVisible: false,
      parkDetail: [],
      rowData: {},
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        { label: this.$t("list.Appearance_time"), value: "exitTime", width: "" },
        {
          label: this.$t("list.amount_receivable"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        { label: this.$t("list.Payment_Type"), value: "paymentTypeDesc", width: "" },
        { label: "支付方式", value: "payTypeDesc", width: "" },
        { label: "支付终端", value: "devTypeDesc", width: "" },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        { label: this.$t("list.Payment_yard"), value: "payParkName", width: "" },
        { label: this.$t("list.Third_party_transaction_ID"), value: "tradeNo", width: "" },
      ],
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        {
          label: "支付方式",
          value: "payTypeName",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      tableOrderdata: [],
      parkRecordIdData: [],
      entryPic: [],
      exitPic: [],
      total1: 0,
      sysmgMsgRecordIds: [],
      disabled: true,
    };
  },
  components: {
    picDetail,
  },
  computed: {
    subscriptionFlag: function () {
      if (this.sysmgMsgRecordIds.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    checkboxSelect(row) {
      return row.unread == 0;
    },
    searchData() {
      if (this.formInline.plateNumber == "") {
        this.formInline.parkId = "";
      }
      // 点击小铃铛重新获取数据
      Bus.$emit("getUnread");
      // GET /sysmgMsgConf/vehicleAlarmRecord
      let opt = {
        url: "/acb/2.0/sysmgMsgConf/vehicleAlarmRecord",
        method: "get",
        data: {
          userId: sessionStorage.userId,
          carId: this.formInline.parkId,
          page: this.pageNum,
          pageSize: this.pageSize,
        },
        success: (res) => {
          if (res.state == 0) {
            if (!res.value) {
              this.tableData = [];
              this.total = 0;
            } else {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            }
            if (this.tableData.length > 0 && this.tableData[0].unread === 0) {
              this.disabled = false;
            } else {
              this.disabled = true;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    handleSelectionChange(val) {
      this.sysmgMsgRecordIds = [];
      val.forEach((e) => {
        if (e.unread == 0) {
          this.sysmgMsgRecordIds.push(e.msgRecordId);
        }
      });
      this.sysmgMsgRecordIds = this.sysmgMsgRecordIds.join(",");
    },
    tagRead(msgRecordId) {
      let sysmgMsgRecordIds = msgRecordId ? `${msgRecordId}` : this.sysmgMsgRecordIds;
      this.$axios
        .post("/acb/2.0/sysmgMsgConf/update", {
          data: {
            sysmgMsgRecordIds,
          },
        })
        .then((res) => {
          this.searchData();
        });
    },
    allRead() {
      this.$confirm("确认要将所有消息标记已读吗?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/sysmgMsgConf/ignore", {
              data: {
                msgType: 2,
              },
            })
            .then((res) => {
              this.searchData();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let url = "/acb/2.0/parkRecord/plateNumber/list";
      this.$axios
        .get(url, {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.state == 0) {
              // res.value.forEach((v) => {
              //   v.value = v.plateNumber;
              // });
              cb(res.value);
            }
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.carId;
    },
    // 停车记录详情
    getRsType(parkRecordId) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/picture/getPictureListByOprNum/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        getDt(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/picture/getPictureListByOprNum/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        getDt(opt);
      }
    },
    // 订单记录
    getOrder(pageNum) {
      let page = pageNum || 1;
      let opt = {
        url: "/acb/2.0/payOrder/payOrderByParkRecordId/" + this.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    refundOrder() {
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.pageNum,
            size: this.pageSize,
            parkRecordId: this.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          if (res.value) {
            this.parkRecordIdData = res.value.list;
          }
        });
    },
    detailShow(data, event, column) {
      Bus.$emit("getUnread");
      this.tagRead(data.msgRecordId);
      this.parkRecordId = data.expand.split(",")[0];
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(this.parkRecordId);
      this.getOrder();
      this.refundOrder();
    },
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
  },
  mounted() {
    this.searchData();
  },
};
</script>

<style lang="stylus" scoped>
.monitorVehicle {
  .tableWrapper{
    .el-table{
      margin-bottom 20px
    }
  }
  .circle {
    display: inline-block;
    position: absolute;
    left: 40px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: blue;
    border-radius: 50%;
  }
}
</style>
